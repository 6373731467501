
import { computed, ref } from 'vue';
import {projectFirestore} from '../../../firebase/config'
import store from '../../../store/index'

function checkTime(opening_hours) {
    const current_day = new Date().getDay()
    const current_time = new Date().toLocaleTimeString();
    const start_time = opening_hours[current_day].start_time
    const end_time = opening_hours[current_day].end_time
    const open = opening_hours[current_day].open
    return Date.parse(`01/01/2011 ${current_time}`) > Date.parse(`01/01/2011 ${start_time}`)
    && Date.parse(`01/01/2011 ${current_time}`) < Date.parse(`01/01/2011 ${end_time}`) && open
}

const add_new_branche = async(data)=>{
    const doc = projectFirestore.collection('Branches').doc()
    data.uid = doc.id
    await doc.set(data)
    return data
}

const get_all_branches = async()=>{
    const docs = await projectFirestore.collection('Branches').get()
    const res = docs.docs.map(doc=>doc.data())
    return res
}

const edit_exist_branche = async(data)=>{
    await projectFirestore.collection('Branches').doc(data.uid)
    .set(data,{merge:true})
}

const delete_branche = async(data)=>{
    await projectFirestore.collection('Branches').doc(data.uid).delete()
}
const role = ref(computed(()=>{
    return store.state.role
}))

export{
    checkTime,
    add_new_branche,
    get_all_branches,
    edit_exist_branche,
    delete_branche,
    role,
}